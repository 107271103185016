<template>
  <div class="app">
    <NavigationBar></NavigationBar>
    <WelcomeSection id="top-anchor"></WelcomeSection>
    <SectionSeparator id="about-us"></SectionSeparator>
    <AboutUsSection></AboutUsSection>
    <SectionSeparator id="our-goals"></SectionSeparator>
    <OurGoalsSection></OurGoalsSection>
    <SectionSeparator id="offer"></SectionSeparator>
    <OfferSection></OfferSection>
    <SectionSeparator id="technology"></SectionSeparator>
    <TechnologySection></TechnologySection>
    <FooterSection></FooterSection>
  </div>
</template>

<script>
import NavigationBar from '../components/NavigationBar.vue'
import WelcomeSection from '../components/WelcomeSection.vue'
import OurGoalsSection from '../components/OurGoalsSection.vue'
import TechnologySection from '../components/TechnologySection.vue'
import SectionSeparator from '../components/SectionSeparator.vue'
import FooterSection from '../components/FooterSection.vue'
import OfferSection from '@/components/OfferSection.vue'
import AboutUsSection from '@/components/AboutUsSection.vue'

export default {
  name: 'MainView',
  components: {
    NavigationBar,
    WelcomeSection,
    OurGoalsSection,
    TechnologySection,
    SectionSeparator,
    FooterSection,
    OfferSection,
    AboutUsSection
}
}

</script>

<style>
* {
  /* outline: 1px red solid; */
}

a {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #107bff;
}

img {
  width: 100%;
}

h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

ul {
  list-style: none;
}

.blue {
  color: #107bff;
}
</style>
