<template>

  <nav class="navbar">
    <div class="navbar-container">

      <!-- to be changed by svg logo -->
      <div class="svg-logo">
        <a href="https://www.simpleformat.pl/">
          <h1 class="navbar-logo"><span class="blue">Simple</span>Format.</h1>
        </a>
      </div>
      <!-- to be changed by svg logo -->

      <ul class="navbar-nav-center">
        <li v-for="item in navItems" :key="item">
          <a :href=item.href> {{$t(item.key)}}</a>
        </li>
      </ul>

      <ul class="navbar-nav-right">
        <li class="navbar-hashtag">
          <a href="https://www.simpleformat.pl/">#Make IT <span class="blue">Simple</span></a>
        </li>
        <li>
          <v-select v-model="$i18n.locale" :options="langs" :clearable="false" :searchable="false" style="text-transform: uppercase; min-width: 80px;"></v-select>
        </li>
      </ul>
    </div>
  </nav>
  <a class="scrollTop" href="#top-anchor" data-aos-offset="660" data-aos-duration="1000" data-aos-delay="400" data-aos="fade-left">
    <i class="fa-solid fa-chevron-up fa-2xl"></i>
  </a>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
  name: 'NavigationBar',
  components: {
    vSelect
  },
  data() {
    return {
      langs: ['pl', 'en'],
      navItems: [
        {key: "nav.about", href: "#about-us"},
        {key: "nav.goals", href: "#our-goals"},
        {key: "nav.offer", href: "#offer"},
        {key: "nav.technology", href: "#technology"},
      ]
    }
  }
}

</script>

<style scoped lang="scss">
.navbar {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  padding: 0 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06), 0 0 2px rgba(0, 0, 0, 0.07);
}

.scrollTop {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  border: 4px solid #107bff;
  background-color: white;
  color: #107bff;
  cursor: pointer;
  border-radius: 100%;
  height: 48px;
  width: 48px;
}

.scrollTop:hover {
  background-color: #ebf4ff;
  transition: background-color 0.2s ease;
}

.svg-logo {
  height: 72px;
  display: flex;
  align-items: center;

  .navbar-logo {
    font-size: 2rem;
  }
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar ul {
  display: flex;
  align-items: center;
}

.navbar li {
  margin: 0 15px;
}

.navbar a {
  color: #000;
  text-decoration: none;
}

.navbar-nav-center {
  text-transform: uppercase;
  flex: 1;
  margin-left: 20px;
}

.navbar-hashtag {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.navbar-nav-right li:nth-child(2) {
  margin: 0 5px;
}

.navbar-container {
  .navbar-nav-center {
    li {
      list-style: none;

      a {
        position: relative;
        display: block;
        text-transform: uppercase;
        font-size: 1.25rem;
        margin: 20px 0;
        padding: 0rem 0.75rem;
        text-decoration: none;
        color: #262626;
        transition: .5s;
        z-index: 1;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-top: 2px solid #262626;
          border-bottom: 2px solid #262626;
          transform: scaleY(2);
          opacity: 0;
          transition: .3s;
        }

        &:after {
          content: '';
          position: absolute;
          top: 2px;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #262626;
          transform: scale(0);
          opacity: 0;
          transition: .3s;
          z-index: -1;
        }

        &:hover {
          color: #fff;

          &:before {
            transform: scaleY(1);
            opacity: 1;
          }

          &:after {
            transform: scaleY(1);
            opacity: 1;
          }
        }
      }
    }
  }
}

.language {
  border: none;
  text-transform: uppercase;
  outline: none;

  option {
    border: none;
    text-transform: uppercase;
  }
}

  @media (max-width: 768px) {
    .svg-logo {
      height: 48px;

      .navbar-logo {
        font-size: 1.625rem;
      }
    }
    .navbar {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .navbar-hashtag {
      display: none;
    }

    .navbar-nav-center {
      display: none !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .navbar-nav-center {
      display: none !important;
    }
  }

  @media (min-width: 1024px) {

  }
</style>
