<template>

  <section class="separator"></section>

</template>

<script>

export default {
  name: 'SectionSeparator',
}

</script>

<style scoped>
.separator {
  height: 0.1px;
  margin-bottom: 56px;
}

@media (max-width: 768px) {
  .separator {
    margin-bottom: 36px;
  }
}
</style>
