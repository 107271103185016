<template>
  <section class="about-us-section">
    <div class="about-us" data-aos-duration="750" data-aos-delay="250" data-aos="fade-right" data-aos-once="true">
      <h3 class="section-title">
        <i class="fa-solid fa-chevron-left"></i> {{$t(title)}}
      </h3>
      <div class="text" v-for="paragraph in description" :key="paragraph">{{$t(paragraph)}}</div>
    </div>
    <div data-aos-duration="750" data-aos-delay="0" data-aos="zoom-in" data-aos-once="true" class="image"></div>
  </section>
</template>

<script>
export default {
  name: 'AboutUsSection',
  data() {
    return {
      title: 'aboutUs.title',
      description: [
        'aboutUs.description.intro',
        'aboutUs.description.goals',
        'aboutUs.description.workmodel'
      ],
    }
  }
}
</script>

<style scoped>
.about-us-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 2rem 0;
}

.about-us-section .image {
  height: 425px;
  background-image: url("../assets/o-nas.jpg");
  background-size: cover;
  background-position: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-title {
  font-size: 2rem;
  padding-bottom: 1rem;
}

.text {
  font-size: 1.125rem;
  padding-bottom: 0.5rem;
}

.about-us-section>div {
  padding: 0 2rem;
}

  @media (max-width: 768px) {
    .about-us-section {
      grid-template-columns: 1fr;
    }

    .about-us-section .image {
      order: 1;
      height: 275px;
      border-radius: 0px;
      margin-left: 0rem;
    }

    .about-us {
      order: 2;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .about-us-section {
      grid-template-columns: 1fr;
    }

    .about-us-section .image {
      order: 1;
      height: 350px;
      border-radius: 0px;
      margin-left: 0rem;
    }

    .about-us {
      order: 2;
    }
  }

  @media (min-width: 1024px) {

  }
</style>
