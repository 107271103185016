<template>
  <footer>
    <hr>
    <div class="footer-cols">
      <ul>
        <li>{{$t(contact)}}</li>
        <li>SimpleFormat Sp. z o.o.</li>
        <li>ul. Żarecka 35</li>
        <li>42-421 Włodowice</li>
        <li>+48 604 346 317</li>
        <li>
          <a href="mailto:biuro@simpleformat.pl">biuro@simpleformat.pl</a>
        </li>
      </ul>
      <ul>
        <li>{{$t(data)}}</li>
        <li>NIP: 6492322065</li>
        <li>KRS: 0000900550</li>
        <li>{{$t(department)}}</li>
        <li>{{$t(register)}}</li>
        <li>{{$t(capital)}}</li>
        <li>{{$t(assets)}}</li>
      </ul>
      <div class="logo">
        <p>Simple<span class="blue">Format.</span></p>
      </div>
    </div>
    <hr>
    <div class="footer-bottom">
      Copyright &copy; 2022 SimpleFormat Sp. z o.o.
    </div>
  </footer>
</template>

<script>

export default {
  name: 'FooterSection',
  data() {
    return {
      contact: 'footer.contact',
      data: 'footer.data',
      department: 'footer.department',
      register: 'footer.register',
      capital: 'footer.capital',
      assets: 'footer.assets',
    }
  }
}

</script>

<style scoped lang="scss">
footer {
  .footer-cols {
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr 2fr;
    padding: 2rem;
    text-align: left;
  }

  .footer-cols {
    font-size: 1.125rem;

    ul li:first-child {
    font-size: 1.375rem;
    padding-bottom: 0.5rem;
  }
  }


  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    font-weight: bold;
  }

  .footer-bottom {
    font-size: 0.875rem;
    text-align: center;
    padding: 0.5rem;
  }

  @media (max-width: 768px) {
    .footer-cols {
        grid-template-columns: 1fr;
        padding: 1rem;
      }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .footer-cols {
        grid-template-columns: 1fr 1fr;
        padding: 1rem;
      }

      .logo {
        grid-column-start: 1;
        grid-column-end: 3;
      }
  }

  @media (min-width: 1024px) {

  }
}
</style>
