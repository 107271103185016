export default {
  "nav": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goals"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])},
    "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])}
  },
  "welcome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazing company"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with extraordinary people."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get to know us !"])}
  },
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "description": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a startup team created as a result of many years of cooperation between people within implementation of various projects of medium and high complexity."])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goals are the implementation of projects and creating solutions with passion for a real user in accordance with the motto ''IT for a human'' and giving satisfaction and development to each of our team. We want to remain an agile team implementation in which our partner always feels full understanding and support in his development a business that we are always learning and we want to fully understand."])},
      "workmodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We work internally in light models. In cases of participation in external projects we work in virtually any model, from classic cascade, through agile, to mixed."])}
    }
  },
  "ourGoals": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goals"])},
    "goals": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be a professional in every aspect of work and delivered solutions"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develop and propagate the acquired knowledge and experience"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build value with the team and thanks to the team"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve the process of software development"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Look beyond the horizon"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never fail"])}
    }
  },
  "offer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our experts will help you with:"])},
    "panels": {
      "0": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUDITS"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["audit performance, substantive support services"])}
      },
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARCHITECTURE"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creating the solution architecture, describing it according to different methodologies"])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SYSTEM ANALISIS"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["developing a detailed needs analysis, designing a solution at any level"])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOLUTION IMPLEMENTATION"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["implementation of the solution: frontend, backend, databases, tests"])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SYSTEM INTEGRATIONS"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["any integration between systems"])}
      },
      "5": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COST ANALISIS"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selection of solution collocation, cost analysis"])}
      },
      "6": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SYSTEM DESIGN"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["working out how, to find weaknesses, to highlight the strengths of the solution"])}
      },
      "7": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOBILE VERSION"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["preparing the mobile version of the solution"])}
      }
    }
  },
  "technology": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are both technologically innovative youth and an open group of experienced professionals. Mutual respect and help provide the foundation for each challenge that determines our continuous development and builds experience. Openness, honesty, understanding expectations and needs, partnership, innovation and comprehensiveness - that's us. Technologies we specialise in:"])}
  },
  "footer": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XVII Economic Department"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the National Court Register"])},
    "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The share capital of the company:"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" PLN 20,000 paid in full"])}
  }
}