<template>
  <section class="offer-layout">
    <div class="offers-title">{{$t(title)}}</div>
    <div class="offers-container">
      <div class='offer-items-grid'>
        <div v-for="card in cards" :key="card" class='card'>
          <div class='card-side front' @touchend="touchFlip(card.id)">
            <div class="card-content">
              <span class="icon material-symbols-outlined">{{card.icon}}</span>
              <div class="title">{{$t(card.title)}}</div>
            </div>
          </div>
          <div class='card-side back'>
            <div class="card-content">
              <div class="description">{{$t(card.description)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OfferSection',
  data() {
    return {
      title: 'offer.title',
      cards: [
        { id: 0, icon: 'fact_check', title: 'offer.panels.0.title', description: 'offer.panels.0.description' },
        { id: 1, icon: 'apartment', title: 'offer.panels.1.title', description: 'offer.panels.1.description' },
        { id: 2, icon: 'query_stats', title: 'offer.panels.2.title', description: 'offer.panels.2.description' },
        { id: 3, icon: 'desktop_windows', title: 'offer.panels.3.title', description: 'offer.panels.3.description' },
        { id: 4, icon: 'developer_board', title: 'offer.panels.4.title', description: 'offer.panels.4.description' },
        { id: 5, icon: 'calculate', title: 'offer.panels.5.title', description: 'offer.panels.5.description' },
        { id: 6, icon: 'architecture', title: 'offer.panels.6.title', description: 'offer.panels.6.description' },
        { id: 7, icon: 'add_to_home_screen', title: 'offer.panels.7.title', description: 'offer.panels.7.description' },
      ]
    }
  },
  methods: {
    touchFlip(id) {
      const card = document.getElementsByClassName('card')[id];
      const front = card.getElementsByClassName('card-side front')[0];
      const back = card.getElementsByClassName('card-side back')[0];

      front.classList.add('mobile-flip-front');
      back.classList.add('mobile-flip-back');

      setTimeout(()=> {
        front.classList.remove('mobile-flip-front');
        back.classList.remove('mobile-flip-back');
      }, 5000)
    }
  }
}
</script>

<style scoped>
.offer-layout {
  background-color: #002048;
  padding: 32px 0;
}

.offers-title {
  text-align: center;
  color: white;
  font-size: 2rem;
  margin: 0 2rem;
}
.offers-container {
  display: flex;
  justify-content: center;
}
.offer-items-grid
 {
  margin: 32px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  margin: 6px;
  min-width: 186px;
  max-width: 186px;
  height: 186px;
  position: relative;
  box-shadow: none;
  background: none;
  flex: 0 0 calc(25% - 20px);
}

.card-side {
  transition: all 0.8s ease;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 18px;
  background-color: #002048;
}

.card-side.back {
  transform: rotateY(-180deg);
}

@media(hover: hover) and (pointer: fine) {
  .card:hover .card-side.front {
    transform: rotateY(180deg);
  }
  .card:hover .card-side.back {
    transform: rotateY(0deg);
  }
}

.mobile-flip-front {
  transform: rotateY(180deg) !important;
}

.mobile-flip-back {
  transform: rotateY(0deg) !important;
}


.card-content {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  height: 60px;
  font-size: 1.25rem;
  text-align: center;
}

.icon {
  padding-bottom: 18px;
  margin-bottom: 9px;
  font-size: 60px;
  border-bottom: 2px solid;
}

.description {
  padding-bottom: 24px;
  font-size: 1.125rem;
  text-align: center;
  border-bottom: 2px solid;
}

@media (max-width: 467px) {
  .card-side {
    padding: 18px;
  }
  .description {
    font-size: 1rem;
  }
  .card {
    margin: 2px;
    min-width: 176px;
  }
  .offers-title {
    margin: 0 2rem;
  }
}

@media (min-width: 467px) and (max-width: 694px) {
  .offer-items-grid
   {
    width: 396px;
  }
}

@media (min-width: 694px) and (max-width: 892px) {
  .offer-items-grid
   {
    width: 594px;
  }
}

@media (min-width: 892px) and (max-width: 1140px) {
  .offer-items-grid
   {
    width: 792px;
  }
}

@media (min-width: 1140px) {
  .offer-items-grid
   {
    width: 1040px;
   }
  .card {
    min-width: 248px;
  }
}
</style>
