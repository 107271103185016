<template>
  <MainView></MainView>
</template>

<script>
import MainView from './views/MainView.vue'

export default {
  name: 'App',
  components: {
    MainView
  }
}
</script>

<style>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Oswald', sans-serif;
  font-size: 1rem;
  background: #f9f9f9;
}

html {
  scroll-behavior: smooth;
}
</style>
