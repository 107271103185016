<template>

<div class="welcome-section">
      <h1 data-aos="fade-in" data-aos-duration="1000" data-aos-delay="200">{{$t(title)}}</h1>
      <h2 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="1000" data-aos-delay="1000">{{$t(subtitle)}}</h2>
      <a data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="2000" href="#about-us" class="enter-button">
        {{$t(button)}}
      </a>
    </div>
</template>

<script>
export default {
  name: 'WelcomeSection',
  data() {
    return {
      title: 'welcome.title',
      subtitle: 'welcome.subtitle',
      button: 'welcome.button'
    }
  }
}
</script>

<style scoped lang="scss">
.welcome-section {
  background-image: url("../assets/background-2.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  h1 {
    text-align: center;
    font-size: 5rem;
    letter-spacing: 0.125rem;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  h2 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .enter-button {
    position: relative;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    display: inline-block;
    cursor: pointer;
    border: blue 0.125em solid;
    padding: .25em 1em;
    border-radius: 0.25em;
  }

  .enter-button::before {
    content: '';
    pointer-events: none;
    background: white;
    position: absolute;
    top: 120%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
    filter: blur(1em);
    opacity: 0;
    transition: opacity 200ms linear, ;
  }

  .enter-button:hover,
  .enter-button:focus {
    // transition: background-color 500ms linear;
    // transition: text-shadow 500ms linear;
    text-shadow: none;
  }

  .enter-button::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 2em 0.5em white;
    opacity: 0;
    transition: opacity 200ms linear;
    z-index: -2;
  }

  .enter-button:hover::after,
  .enter-button:focus::after {
    opacity: 0.3;
  }

  .enter-button:hover::before,
  .enter-button:focus::before {
    opacity: 0;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 4rem;
      line-height: 4rem;
    }

    h2 {
      margin-top: 2rem;
      font-size: 2rem;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {

  }

  @media (min-width: 1024px) {

  }
}
</style>
