export default {
  "nav": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Nas"])},
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasze cele"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
    "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologia"])}
  },
  "welcome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjątkowa firma"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z niezwykłymi ludźmi."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznaj nas !"])}
  },
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nas"])},
    "description": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jesteśmy zespołem startupowym powstałym w wyniku wieloletniej współpracy osób w ramach realizacji różnych projektów o średniej i wysokiej złożoności."])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasze cele to realizacja projektów oraz tworzenie rozwiązań z pasją, stanowiących wartość dla realnego użytkownika oraz dających satysfakcję i rozwój każdemu z naszego zespołu, zgodnie z motto „IT dla człowieka”. Pragniemy pozostać zwinnym zespołem realizacyjnym, w którym nasz Partner zawsze czuje pełne zrozumienie i wsparcie w rozwoju własnego biznesu, którego się zawsze uczymy i chcemy w pełni rozumieć."])},
      "workmodel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracujemy wewnętrznie w modelach lekkich. W przypadku udziału w projektach zewnętrznych pracujemy praktycznie w dowolnych modelach - od klasycznych kaskadowych przez zwinne aż do mieszanych."])}
    }
  },
  "ourGoals": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasze cele"])},
    "goals": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Być wsparciem na każdym etapie projektu realizowanego dla klienta"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwijać się i propagować zdobytą wiedzę oraz doświadczenie"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budować wartość zespołem i dzięki zespołowi"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmieniać podejście do tworzenia oprogramowania"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patrzeć poza horyzont"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigdy nie zawieść"])}
    }
  },
  "offer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasi specjaliści efektywnie pomogą Ci w:"])},
    "panels": {
      "0": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUDYTACH"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wykonaniu audytu i usługach wsparcia merytorycznego"])}
      },
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARCHITEKTURZE"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stworzeniu architektury rozwiązania oraz opisaniu jej zgodnie z różnymi metodykami"])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANALIZIE SYSTEMU"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analizie potrzeb i zaprojektowaniu rozwiązania na dowolnym poziomie"])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMPLEMENTACJI ROZWIĄZANIA"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["implementacji rozwiązania: frontend, backend, bazy danych, testy"])}
      },
      "4": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTEGRACJI SYSTEMÓW"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w dowolnych integracjach pomiędzy systemami"])}
      },
      "5": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANALIZIE FINANSOWEJ"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doborze kolokacji rozwiązania i analizie finansowej"])}
      },
      "6": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROJEKCIE ROZWIĄZANIA"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wymyśleniu jak, znalezieniu słabych i podkreśleniu mocnych stron rozwiązania"])}
      },
      "7": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WERSJI MOBILNEJ"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["przygotowaniu wersji mobilnej rozwiązania"])}
      }
    }
  },
  "technology": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologia"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My to zarówno innowacyjna technologicznie młodzież, jak i otwarta grupa doświadczonych profesjonalistów. Wzajemny szacunek i pomoc daje fundament do realizacji każdego wyzwania, które determinuje nasz nieustający rozwój i buduje doświadczenie. Otwartość, szczerość, wczucie się w oczekiwania i potrzeby, partnerstwo, innowacyjność i kompleksowość – to po prostu my. Technologie, w których pracujemy:"])}
  },
  "footer": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XVII Wydział Gospodarczego"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajowego Rejestru Sądowego"])},
    "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitał zakładowy spółki"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 000 zł w całości wpłacony"])}
  }
}