<template>
  <section class="our-goals-section">
    <div data-aos-duration="750" data-aos-delay="0" data-aos="zoom-in" data-aos-once="true" class="image"></div>
    <div data-aos-duration="750" data-aos-delay="250" data-aos="fade-right" data-aos-once="true">
      <h3 class="section-title">
        <i class="fa-solid fa-chevron-right"></i> {{$t(title)}}
      </h3>
      <div class="aims">
        <div v-for="goal in goals" :key="goal">
          {{$t(goal.key)}}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OurGoalsSection',
  data() {
    return {
      title: 'ourGoals.title',
      goals: [
        {key: 'ourGoals.goals.0', delay: '800'},
        {key: 'ourGoals.goals.1', delay: '1200'},
        {key: 'ourGoals.goals.2', delay: '1600'},
        {key: 'ourGoals.goals.3', delay: '2000'},
        {key: 'ourGoals.goals.4', delay: '2400'},
        {key: 'ourGoals.goals.5', delay: '2800'},
      ],
    }
  }
}
</script>

<style scoped>
.our-goals-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 2rem 0;
}

.section-title {
  font-size: 2rem;
  padding-bottom: 2rem;
}

.aims {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  font-size: 1.25rem;
}

.our-goals-section .image {
  height: 425px;
  background-image: url("../assets/nasze-cele.jpg");
  background-size: cover;
  background-position: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-goals-section>div {
  padding: 0 2rem;
}

  @media (max-width: 768px) {
    .our-goals-section {
      grid-template-columns: 1fr;
    }

    .our-goals-section .image {
      height: 275px;
      border-radius: 0px;
      margin-left: 0rem;
    }

    .aims {
      grid-template-columns: 1fr;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .our-goals-section {
      grid-template-columns: 1fr;
    }

    .our-goals-section .image {
      height: 350px;
      border-radius: 0px;
      margin-left: 0rem;
    }
  }

  @media (min-width: 1024px) {

  }
</style>
