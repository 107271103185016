<template>

  <section class="technology">
    <h3 class="section-title">{{$t(title)}}</h3>
    <div class="text">{{$t(description)}}</div>
    <div class="slider-container">
      <button class="previous slider-btn">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
      <swiper :slidesPerView="1" :spaceBetween="20" :navigation="{nextEl: '.next', prevEl: '.previous',}"
        :modules="modules" :loop="true" :speed="800" :autoplay="{delay: 3000, disableOnInteraction: false}" :breakpoints="breakpoints">
        <swiper-slide><img src="../assets/angular-logo.svg" /></swiper-slide>
        <swiper-slide><img src="../assets/csharp-logo.svg"></swiper-slide>
        <swiper-slide><img src="../assets/css-logo.svg"></swiper-slide>
        <swiper-slide><img src="../assets/dotnet-logo.svg"></swiper-slide>
        <swiper-slide><img src="../assets/html-logo.svg"></swiper-slide>
        <swiper-slide><img src="../assets/java-logo.svg"></swiper-slide>
        <swiper-slide><img src="../assets/js-logo.svg"></swiper-slide>
        <swiper-slide><img src="../assets/mssql-logo.svg"></swiper-slide>
        <swiper-slide><img src="../assets/node-logo.svg"></swiper-slide>
        <swiper-slide><img src="../assets/oracle-logo.svg"></swiper-slide>
        <swiper-slide><img src="../assets/react-logo.svg"></swiper-slide>
        <swiper-slide><img src="../assets/vue-logo.svg"></swiper-slide>
      </swiper>
      <button class="next slider-btn">
        <i class="fa-solid fa-chevron-right"></i>
      </button>
    </div>
  </section>

</template>

<script>
import 'swiper/css';
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Navigation } from "swiper";

export default {
  name: 'TechnologySection',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Navigation],
    };
  },
  data() {
    return {
      title: 'technology.title',
      description: 'technology.description',
      breakpoints: {
          '360': {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          '720': {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          '1024': {
            slidesPerView: 5,
            spaceBetween: 30,
          },
          '1420': {
            slidesPerView: 7,
            spaceBetween: 40,
          },
      }
    }
  }
}
</script>

<style scoped lang="scss">

.section-title {
  font-size: 2rem;
  padding-bottom: 1rem;
  text-align: center;
}

.text {
  font-size: 1.125rem;
  padding-bottom: 2rem;
  text-align: center;
}

.technology {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

img {
  width: 100%;
  min-height: 180px;
  max-height: 200px;
}

.slider-container {
  display: flex;
}

.slider-btn {
  background: none;
  border: none;
  font-size: 42px;
  padding: 16px;
}

.slider-btn i {
  color: #107bff;
  cursor: pointer;
}
</style>
